import React from "react"
import { graphql } from "gatsby"
import { normalizeAboutPage } from "../helpers"
import { allRoutes } from "../helpers/routes"
import Layout from "../components/layout"
import LinkBtn from "../components/linkBtn"
import GoogleMap from "../components/map"
import Img from "gatsby-image"

const AboutPage = ({ data }) => {
  const { intro, description, buildingUpdate } = normalizeAboutPage(data.allContentfulAboutTheJcc.edges[0].node)
  const jccAddress = "7900 Northaven Road Dallas, TX 75230";
  return (
    <Layout>
      <header className="sponsor__header sponsor__header--about">
        <div className="content-area">
          <h1>About the Senior Adult Department</h1>
          <p className="sponsor__tagline">{intro}</p>
        </div>
      </header>
      <div className="content-area">
        <article className="sponsor">
          <div className="sponsor-content">
            <Img className="sponsor-content__img" fluid={data.smilingSeniors.childImageSharp.fluid} alt="Smiling seniors wearing sunglasses" />
            <div className="rich-text" dangerouslySetInnerHTML={{ __html: description }} />
          </div>
          <div className="sponsor-sidebar">
            <h2>Contact Information</h2>
            <GoogleMap address={jccAddress} />
            <div className="sponsor__address">
              <span>7900 Northaven Road</span>
              <span className="separator">{" "}•{" "}</span>
              <span>Dallas, Texas 75230</span>
            </div>
            {buildingUpdate && <p className="sponsor__address-note">{buildingUpdate}</p>}
          </div>
        </article>
        <div className="cta-block-bottom">
          <LinkBtn className="btn" to={allRoutes.seniorWebsite}>
            Learn more about senior programs
          </LinkBtn>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query AboutPageQuery {
    allContentfulAboutTheJcc {
      edges {
        node {
          shortIntroduction
          information {
            childContentfulRichText {
              html
            }
          }
          buildingUpdate
        }
      }
    }
    smilingSeniors: file(relativePath: { eq: "smiling-seniors.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

export default AboutPage